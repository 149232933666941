<template>
	<v-card class="substation-tile" v-on="$listeners" :ripple="false">
		<v-card-text class="substation-body">
			<div class="station-name">{{ substation ? substation.name : "Empty" }}</div>
		</v-card-text>
		<v-card-text class="substation-body">
			<div class="station-registrators">Количество регистраторов: 1</div>
		</v-card-text>
		<v-card-text class="substation-body">
			<div class="station-registrators">Дежурный: Иванов С.С.</div>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	name: "SubstationCardComponent",
	props: {
		substation: {
			type: Object,
			required: true,
			default: null
		}
	}
};
</script>

<style scoped lang="scss">
.substation-tile:hover {
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
	transition: box-shadow 0.2s ease-in-out;
}

.substation-tile {
	cursor: pointer;
	margin: 15px;

	.substation-body {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.station-name {
			font-size: 20px;
			font-weight: 500;
			width: 45%;
		}

		.date,
		.time {
			display: flex;
			align-items: center;

			.icon {
				margin-right: 10px;
			}
		}
	}
}
</style>
