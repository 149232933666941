<template>
	<div class="substation-list wrapper">
		<div class="substations">
			<SubstationCardComponent
				class="ticket"
				v-for="substation in substations"
				:key="substation.id"
				:substation="substation"
			/>
		</div>
	</div>
</template>

<script>
import SubstationCardComponent from "@/components/SubstationCardComponent";
import SubstationService from "@/services/SubstationService";

export default {
	name: "SubstationList",
	components: {
		SubstationCardComponent
	},
	async mounted() {
		this.substations = await SubstationService.getAll();
	},
	data: () => ({
		substations: []
	})
};
</script>

<style scoped lang="scss">
.substation-list {
	margin-top: 35px;
}
</style>
